body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.NavBar {
  background-color: #282c34;
  height: 50px; /* Set a shorter height */
  display: flex;
  align-items: center; /* Vertically center the links */
  justify-content: center; /* Center the links horizontally */
  position: sticky; /* Make it sticky so it stays at the top when scrolling */
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensure it stays above other content */
}

.NavBar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.NavBar ul li {
  margin: 0 15px;
}

.NavBar ul li a {
  text-decoration: none;
  color: #89dc65;
  font-size: 16px; /* Adjust the font size as needed */
}

.NavBar ul li a:hover {
  text-decoration: underline;
}
